import { AccessScope } from '@electrifly/central-client-api';
import {
    Button,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { HolderLink } from '../holders/elements/HolderLink';
import { OperatorLink } from '../operators/elements/OperatorLink';
import { WithAccessScope } from '../wrappers/WithAccessScope';
import { useChargeLocation } from '../wrappers/WithChargeLocation';
import { useChangeLocationChangeHolderDialog } from './dialogs/ChargeLocationChangeHolder';
import { useChargeLocationRemoveDialog } from './dialogs/ChargeLocationRemove';
import { useState } from 'react';
import { useRegistryEditor } from '../../services/GlobalRegistryEditor';
import { EditableValue } from '../editable-values/EditableValue';

function AdministativeBlock() {
    const location = useChargeLocation();
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Администрирование</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Оператор" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={<OperatorLink id={location.operator} />} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Владелец" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={<HolderLink id={location.holder} />} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Тег владельца" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={location.subHolderName || ''}
                            onNewValue={newValue => updateChargeLocation(location, { subHolderName: newValue })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </>
    );
}

function ActionsBlock() {
    const location = useChargeLocation();
    const showChangeLocationChangeHolderDialog = useChangeLocationChangeHolderDialog(store => store.show);
    const showChargeLocationRemoveDialog = useChargeLocationRemoveDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <WithAccessScope scope={AccessScope.OPERATOR}>
                        <ListItem divider>
                            <ListItemText primary="Изменить владельца" secondary="Перенос зарядной локации" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showChangeLocationChangeHolderDialog(location._id)}
                                >
                                    Изменить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </WithAccessScope>
                    <WithAccessScope scope={AccessScope.GLOBAL}>
                        <ListItem divider>
                            <ListItemText primary="Изменить оператора" secondary="Перенос зарядной локации" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showChangeLocationChangeHolderDialog(location._id, true)}
                                >
                                    Изменить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </WithAccessScope>

                    <ListItem>
                        <ListItemText primary="Удаление" secondary="Удаление зарядной локации" />
                        <ListItemSecondaryAction>
                            <Button
                                fullWidth
                                variant="contained"
                                color="error"
                                onClick={() => showChargeLocationRemoveDialog(location._id)}
                            >
                                Удалить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Stack>
        </>
    );
}

function ContactsBlock() {
    const location = useChargeLocation();
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const [value, setValue] = useState(location.comment);

    const save = async () => {
        if (location.comment === value) {
            return;
        }
        await updateChargeLocation(location, { comment: value });
    };

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Контакты</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Paper>
                <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    variant="outlined"
                    hiddenLabel={true}
                    value={value}
                    onChange={element => setValue(element.currentTarget.value)}
                    onBlur={element => save()}
                    InputProps={{
                        autoComplete: 'off',
                    }}
                />
            </Paper>
        </>
    );
}

function PageInternal() {
    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <AdministativeBlock />
            </Grid>
            <Grid item lg={6} xs={12}>
                <ActionsBlock />
            </Grid>
            <Grid item xs={12}>
                <ContactsBlock />
            </Grid>
        </Grid>
    );
}

export function ChargeLocationManagementPage() {
    return <PageInternal />;
}
